<template>
<div>
  <div class="home">
    <NavBar />
    <header id="event-subscription">
      <video id="video-principal" autoplay slow loop muted>
          <source src="../assets/videos/video-site.mp4" type="video/webm">
          <source src="../assets/videos/video-site.mp4" type="video/mp4">
      </video>
    </header>
    <CarouselsMain class="crrouselsImg" />
  </div>
  <section id="key-benefits">
    <h2  class="neon-mode">Destaques</h2>
    <div class="benefits hidden">
      <div class="benefit">
        <div id="benefit-1">
          <router-link top="top" to="/service">
          <img class="benefit-img " src="../assets/img/veiculos/polimento-bmw.jpg" alt="" />
          </router-link>
        </div>
        <p>
          Polimento 
        </p>
      </div>
      <div class="benefit">
        <div id="benefit-1">
          <router-link top="top" to="/service">
          <img class="benefit-img " src="../assets/img/veiculos/foto-vertical-ppf.jpg" alt="" />
          </router-link>
        </div>
        <p>
          PPF 
        </p>
      </div>
      <div class="benefit">
        <div id="benefit-2">
          <router-link top="top" to="/service">
            <img class="benefit-img " src="../assets/img/veiculos/higienizacaoInterna.jpg" alt="" />
            </router-link>
        </div>
        <p>
          Higienização Interna
        </p>
      </div>
      <div class="benefit">
        <div id="benefit-3">
          <router-link top="top" to="/service">
          <img class="benefit-img " src="../assets/img/veiculos/foto-vitrificacao-vertical.jpg" alt="" />
          </router-link>
        </div>
        <p>
         Vitrificação de Pintura
        </p>
      </div>
    </div>
    <div class="btn-benefit">
        <router-link class="btn" top="top" to="/service"><button>Conheça a lista completa</button></router-link>
    </div>
  </section>
  <section id="location">
    <div id="address" class="hiddenPage">
      <i class="bi bi-geo-alt-fill"></i>
      <div id="address-details">
        <p>Av. Pres. Antônio Carlos, 7826</p>
        <p>São Luiz, Belo Horizonte - MG</p>
        <p>Abertura: 8:00 horas</p>
      </div>
    </div>
    <div id="about-location" class="hiddenPage">
      <h3>Local da empresa</h3>
      <router-link to="/location"><button class="btn" >Veja no mapa</button></router-link>
    </div>
  </section>
  <section id="details">
    <div class="detail" id="detail-1">
      <img class="borderImg sombras hiddenPage" src="../assets/img/veiculos/foto-quemsomos.jpg" alt="" />
      <div class="detail-description">
        <h3 class="hiddenPage" >Quem somos?</h3>
        <p class="hiddenPage">
          Criada em 2020, a Custom Garage é uma
          empresa especializada na área da
          Estética Automotiva. Somos a equipe que
          atende os apaixados por carros, detalhes
          e higiene de qualidade.

        </p>
      </div>
    </div>
    <div class="detail" id="detail-2">
      <div class="detail-description">
        <h3 class="hiddenPage">Nossos Valores:</h3>
        <p class="hiddenPage">
          Honestidade, compromisso e dedicação
          total ao cliente e a um dos seus bens
          mais importantes, buscando sempre
          corresponder a expectativa e confiança
          depositada em nosso trabalho.
        </p>
      </div>
      <img class="borderImg sombras hiddenPage" src="../assets/img/veiculos/foto-nossosvalores.jpg" alt="" />
    </div>
    <div class="detail" id="detail-3">
      <img class="borderImg sombras hiddenPage" src="../assets/img/veiculos/foto-nossoobjetivo.jpg" alt="" />
      <div class="detail-description">
        <h3 class="hiddenPage">Nosso Objetivo:</h3>
        <p class="hiddenPage">
          O nosso objetivo é cuidar do seu veículo e te
          proporcionar uma experiência
          diferenciada, oferecendo um
          serviço de qualidade.

        </p>
      </div>
    </div> 
  </section>
  <section id="cta">
    <h3>Acompanhem nossos trabalhos</h3>
     <router-link top="top" to="/social">
          <button class="btn borderImg" type="text">Acompanhar</button>
      </router-link>
  </section>
  <footer id="footer">
      <h3>Custom Garage</h3>
      <p>A evolução da estética automotiva</p>
      <p><span>Entre em contato:</span></p>
      <p><span>Telefone:</span> (31)97561-2861</p>
      <br>
      <p><span>By:</span> <a class="colorLinkDevs" href="https://www.linkedin.com/in/joão-victor-geraldini-464317206/">João Victor</a> <span>and</span> <a class="colorLinkDevs" href="https://www.linkedin.com/in/ian-linces-b0156a157/">Ian Linces</a></p>
    </footer>
  <Whats />
</div>
</template>

<script>

// @ is an alias to /src
import NavBar from '@/components/NavBar.vue'
import Whats from '@/components/Whats.vue'
import CarouselsMain from '@/components/CarouselsMain.vue'

export default {
  name: 'HomeView',
  components: {
    NavBar,
    Whats,
    CarouselsMain
  },
  data() {
    return {
      top:0,
      currentImageIndex: 0,
    };
  },
 
  methods: {
    animationX(){
      const myObserver = new IntersectionObserver((entries) => {
        entries.forEach( (entry) => {
          if(entry.isIntersecting){
            entry.target.classList.add('show')
          } else{
            entry.target.classList.remove('show')
          }
        });
      })
      const elements = document.querySelectorAll('.hidden')
      elements.forEach((element) => myObserver.observe(element))

    },
    animationY(){
      const myObserver = new IntersectionObserver((entries) => {
        entries.forEach( (entry) => {
          if(entry.isIntersecting){
            entry.target.classList.add('showPage')
          } else{
            entry.target.classList.remove('showPage')
          }
        });
      })
      const elements = document.querySelectorAll('.hiddenPage')
      elements.forEach((element) => myObserver.observe(element))     

    }
  
  },
  mounted(){
    this.animationX()
    this.animationY()
    // Verificar se o dispositivo é um dispositivo móvel
    const isMobile = /iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent);

    // Se for um dispositivo móvel, remover o atributo autoplay do vídeo
    if (isMobile) {
        const videoElement = document.getElementById("video-principal");
        videoElement.removeAttribute("autoplay");
    }
  },
}
</script>
<style scoped>
/* Estilos para a imagem */
#video-principal{
  display: block; /* Inicia com a imagem oculta */
}
/* Estilos para o vídeo */
.crrouselsImg{
  display: none; /* Inicia com o vídeo visível */
}

/* Media query para telas menores que 400 pixels */
@media (max-width: 450px) {
  .crrouselsImg  {
    display: block; /* Mostra a imagem */
  }
  #video-principal {
    display: none; /* Esconde o vídeo */
  }
}
.hidden{
  opacity: 0;
  filter: blur(15px);
  transform: translateX(-100%);
  transition: all 1s;
}
.show{
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
  transition: all 1s;
}
.hiddenPage{
  opacity: 0;
  filter: blur(15px);
  transition: all 0.6s;
}
.showPage{
  opacity: 1;
  filter: blur(0);
  transition: all 0.6s;
}
.btn-benefit{
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn{
  text-align: center;
  margin: auto;
  min-width: 290px;
  max-width: 390px;
}
.btn button{
  text-transform: uppercase;
}
  #event-subscription {
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
  }

</style>