<template>
    <div>
        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css">
        <a href="https://api.whatsapp.com/send/?phone=5531975612861&text&type=phone_number&app_absent=0" class="whatsIcon" target="_blank">
            <i class="fa fa-whatsapp"></i>
        </a>
    </div>
</template>

<script>
export default {
  name: 'Whats',
}
</script>
