<template>
<v-carousel
  class="carousel"
  :continuous="false"
  :show-arrows="false"
  >

  <v-carousel-item
    src="../assets/img/veiculos/foto-pricipal-mobile.jpg"
    cover
  ></v-carousel-item>

  
  <v-carousel-item
    src="../assets/img/veiculos/foto-vitrificacao-vertical.jpg"
    cover
  ></v-carousel-item>
</v-carousel>
 
</template>

<script>
export default {
  name: 'carouselMain',
}
</script>
<style scoped>
.carousel{
  width: 100%;
  
  height: 50%;
  max-height: 450px;
}


</style>