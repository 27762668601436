<template>
  <div class="navbar sombras" :class="{ open: open }" id="navbar">
    <div> <img class="logo" src="../assets/img/logo01.jpg" alt=""></div>
    <img class="burger"  src="../assets/burger.svg" @click="toggleMenu" alt="">
    <div class="navclear"></div>
    
    <div class="topnav">
      <router-link class="navelement navlink" to="/"><span class="navelementSpan">Home</span></router-link>
      <router-link class="navelement navlink" to="/service"><span class="navelementSpan">Serviços</span></router-link>
      <router-link class="navelement navlink" to="/social"><span class="navelementSpan">Social</span></router-link>
      <router-link class="navelement navlink" to="/parceiros"><span class="navelementSpan">Parceiros</span></router-link>
      <router-link class="navelement navlink espacoLocalizacao navelementSpan" to="/location" >Localização</router-link>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  data() {
    return {
      open: false
    }
  },
  methods: {
    openMenu () {
      this.open = true
    },
    closeMenu () {
      this.open = false
    },
    toggleMenu () {
      this.open = !this.open
    },
    openSubMenu (e) {
      var nav = e.target.getElementsByClassName('subnav')
      
      if (nav.length > 0) {
        nav = nav[0]
      }

      nav.classList.toggle('open')
    }
  }
}
</script>
<style scoped>

.navbar {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    color: #fff;
    background:  #1b2c51;
    font-size: 17px;
    max-height: 70px;
    z-index: 9;
  }
  .navelementSpan{
    font-family: 'BrandingSF-SemiBold';
  }
  .navbar .logo {
    display: block;
    float: left;
    margin-left: 100px;
    width: 80px; 
  }
  
  .navbar .navlink {
    margin-top: 7px;
    color: inherit;
    text-decoration: inherit;
    background-color: #1b2c51;
  }
  .navlink:hover {
    border-top: 2px solid #3b96c7;
    border-bottom: 2px solid #3b96c7;
    filter: blur(1px);
  transition: all 1s;
  }
  .espacoLocalizacao{
    margin-right: 100px;
  }
  
  .navbar .burger {
  
    float: right;
    padding: 14px 10px;
    margin-right: 10px;
    margin-top: 5px;
    width: 45px;
  }
  
  .navbar .topnav {
    display: none;
    margin: 0;
    padding: 0;
  }
  
  .navbar.open .topnav {
    display: block;
    margin-bottom: 40px;
  }
  .navbar.open .topnav a{
    margin-top: 30px;
    text-align: center;
  }
  
  .navbar .navelement {
    cursor: pointer;
    float: none;
    display: block;
    text-align: left;
    padding: 14px 20px;
    text-decoration: none;
  }
  
  .navbar .subnav {
    display: block;
    margin-top: 10px;
    max-height: 430px;
    overflow: hidden;
    transition: max-height 0.15s ease-in;
  }
  
  .navbar .subnav:not(.open) {
    max-height: 0;
    transition: max-height 0.25s ease-out;
  }
  
  .navbar .subnav .navelement {
    float: none;
    display: block;
    text-align: left;
    padding: 5px 10px;
    text-decoration: none;
  }
  
  .navbar .navclear {
    clear:both;
  }
  @media screen and (max-height: 500px) {
    .navbar.open .topnav a{
        margin-top: 0;
      }
      .navbar.open .topnav {
        margin-bottom: 0;
      }
  }

  @media screen and (max-width: 800px) {
    .navbar .logo {
      margin-left: 10px;
    }
    .espacoLocalizacao{
      margin-right: 0px;
    }
    .navbar{
      max-height: none;
    }
  }
  
  
  @media screen and (min-width: 800px) {
    .navbar .burger {
      display: none;
    }
  
    .navbar .navclear {
      clear:none;
    }
  
    .navbar .topnav {
      display: block;
      float: right;
    }
  
    .navbar .navelement {
      float: left;
    }

    .navbar .navelement:hover {
      background-color: #1b2c51;
    }
  
    .navbar .navdropdown {
      position: relative;
    }
  
    .navbar .subnav {
      position: absolute;
      right: 0;
      background-color: #333;
      min-width: 200px;
    }
}
</style>

